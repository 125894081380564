// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-challenges-evolving-tsx": () => import("./../../../src/pages/challenges/evolving.tsx" /* webpackChunkName: "component---src-pages-challenges-evolving-tsx" */),
  "component---src-pages-challenges-scaling-tsx": () => import("./../../../src/pages/challenges/scaling.tsx" /* webpackChunkName: "component---src-pages-challenges-scaling-tsx" */),
  "component---src-pages-challenges-validating-tsx": () => import("./../../../src/pages/challenges/validating.tsx" /* webpackChunkName: "component---src-pages-challenges-validating-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-references-10-day-cloud-onboarding-tsx": () => import("./../../../src/pages/references/10-day-cloud-onboarding.tsx" /* webpackChunkName: "component---src-pages-references-10-day-cloud-onboarding-tsx" */),
  "component---src-pages-references-data-driven-experimentation-tsx": () => import("./../../../src/pages/references/data-driven-experimentation.tsx" /* webpackChunkName: "component---src-pages-references-data-driven-experimentation-tsx" */),
  "component---src-pages-references-microfrontends-tsx": () => import("./../../../src/pages/references/microfrontends.tsx" /* webpackChunkName: "component---src-pages-references-microfrontends-tsx" */),
  "component---src-pages-references-new-application-tsx": () => import("./../../../src/pages/references/new-application.tsx" /* webpackChunkName: "component---src-pages-references-new-application-tsx" */),
  "component---src-pages-references-serverless-backend-tsx": () => import("./../../../src/pages/references/serverless-backend.tsx" /* webpackChunkName: "component---src-pages-references-serverless-backend-tsx" */),
  "component---src-pages-tracking-privacy-policy-tsx": () => import("./../../../src/pages/tracking-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-tracking-privacy-policy-tsx" */)
}

