import { createGlobalStyle } from "styled-components"
import media from "styled-media-query"
import { fontFamilyDefault, fontFamilyHeading } from "./theme"
import { clamp } from "./helpers"

const GlobalStyles = createGlobalStyle`
  :root {
    white-space: pre-line;
  }

  html, body {
    font-size: ${clamp(1, 1.125)};
  }
  
  body {
    // @ts-ignore
    color: ${({ theme }) => theme.semanticColor.body_text_dark};
    ${fontFamilyDefault};
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
  
  html {
    font-size: ${({ theme }) => theme.size.font_body_px};
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  p {
    line-height: 1.4;
  }
  
  a {
    text-decoration: none;
    
    &, &:visited, &:hover, &:active, &:active {
      color: inherit;
    }
  }
  
  img {
    max-width: 100%;
  }
  
  h1, h2, h3, h4, h5, h6 {
    ${fontFamilyHeading}
  }
  
  h2 {
    margin: ${clamp(1, 2)} 0;
  }
  

  .full-bleed {
    margin-left: calc(50% - 50vw);
    width: 100vw;
    margin-top: 2rem;
    margin-bottom: 2rem;

  ${media.greaterThan("huge")`
    margin-left: calc(50% - 720px);
    width: 1440px; // the 'huge' breakpoint is at 1440px
  `}
}
`

export default GlobalStyles
