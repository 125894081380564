import { ThemeProvider } from "styled-components"
import { theme } from "./src/styles/theme"
import React from "react"
import { Normalize } from "styled-normalize"
import GlobalStyles from "./src/styles/global-styles"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <Normalize />
      <GlobalStyles />
      {element}
    </ThemeProvider>
  )
}
