import { theme } from "./theme"
import { getLuminance } from "polished"

const getClampFunctionBase = (
  minSizeRem: number,
  maxSizeRem: number,
  minWidthPx: number,
  maxWidthPx: number,
  pixelsPerRem: number
) => {
  const minWidth = minWidthPx / pixelsPerRem
  const maxWidth = maxWidthPx / pixelsPerRem

  const slope = (maxSizeRem - minSizeRem) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minSizeRem

  return `clamp(${minSizeRem}rem, ${yAxisIntersection.toFixed(4)}rem + ${(
    slope * 100
  ).toFixed(4)}vw, ${maxSizeRem}rem)`
}

export const clamp = (minSizeRem: number, maxSizeRem: number) =>
  getClampFunctionBase(
    minSizeRem,
    maxSizeRem,
    320,
    1440,
    theme.size.font_body_px
  )

export const darkerColor = (colorA: string, colorB: string) => {
  const luminanceA = getLuminance(colorA)
  const luminanceB = getLuminance(colorB)

  return luminanceA < luminanceB ? colorA : colorB
}
