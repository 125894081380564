import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

const SEO = ({ description, meta, title, ogImage, location, canonicalUrl }: SEOProps) => {
  const { formatMessage, locale } = useIntl()

  const { site, defaultOgImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            description
          }
        }
        defaultOgImage: file(relativePath: { eq: "defaultOgImage.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const metaDescription =
    description || formatMessage({ id: "seo.description" })
  const ogImageUrl = `https://${process.env.REACT_APP_WEBSITE_URL}${
    ogImage || defaultOgImage.childImageSharp.fluid.src
  }`
  const url = `https://${process.env.REACT_APP_WEBSITE_URL}${
    location ? location.pathname : ""
  }`

  const actualTitle = title ?? site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={actualTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={canonicalUrl ? [
        {rel:`canonical`, href: canonicalUrl}
      ] : []}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: site.siteMetadata.author,
        },
        {
          property: `og:title`,
          content: `${actualTitle} | ${site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: "og:image",
          content: ogImageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: "twitter:image",
          content: ogImageUrl,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta || [])}
    />
  )
}

interface SEOProps {
  description?: string
  lang?: string
  meta?: [
    {
      name: string
      content: string
    }
  ]
  title: string
  ogImage?: string
  location?: Location
  canonicalUrl?: string
}

export default SEO
