import { css } from "styled-components"
import { darken, lighten } from "polished"

export const color = {
  red: "#ef476f",
  yellow: "#ffd166",
  green: "#06d6a0",
  darkblue: "#3E3D56",
  royalblue: "#118ab2",
  lightgray: "#f5f5f5",
}

const size = {
  font_body_px: 18,
  width_limited_s: 900,
  width_limited_m: 1400,
}

export const theme = {
  color,
  size,
  semanticColor: {
    primary: color.darkblue,
    secondary: "white",

    validating_bg: "#ff75a0",
    validating_fg: darken(0.5, "#ff75a0"),

    scaling_bg: "#fce38a",
    scaling_fg: darken(0.5, "#fce38a"),

    evolving_bg: "#95e1d3",
    evolving_fg: darken(0.5, "#95e1d3"),

    body_text_dark: "black",
    body_text_light: "white",
  },
}

export const fontFamilyDefault = css`
  font-family: Inter, sans-serif;
`

export const fontFamilyHeading = css`
  font-family: "Inter", sans-serif;
`

export const boxShadowSoft = css`
  box-shadow: 0px 42px 67px rgba(0, 0, 0, 0.0363503),
    0px 11.2061px 14.9653px rgba(0, 0, 0, 0.0536497),
    0px 3.50746px 4.45557px rgba(0, 0, 0, 0.09);
`

export const boxShadowSoftHover = css`
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07),
    0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
`

export const boxShadowDefault = css`
  ${boxShadowSoft};

  &:hover {
    ${boxShadowSoftHover};
  }
`

export const gradient = {
  default: css`
    background: linear-gradient(
        135deg,
        ${theme.color.green} 0%,
        ${theme.color.royalblue} 100%
      ),
      linear-gradient(0deg, gray(0, 0.2), rgba(0, 0, 0, 0.2));
  `,
}

export const backgroundImageWithGradient = (
  imageSrc: string,
  colorA: string,
  colorB: string,
  withHover?: boolean
) => css`
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(135deg, ${colorA} 0%, ${colorB} 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5)),
    url(${imageSrc});
  background-blend-mode: screen, normal, normal;

  ${withHover
    ? css`
        &:hover {
          background-image: linear-gradient(
              135deg,
              ${lighten(0.05, colorA)} 0%,
              ${lighten(0.05, colorB)} 100%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5)),
            url(${imageSrc});
        }
      `
    : ""}
`
